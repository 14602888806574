import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { graphql, withApollo } from 'react-apollo'
import { propType } from 'graphql-anywhere'

import {
  compose,
  branch,
  renderNothing,
  renderComponent,
  withProps,
  lifecycle,
  setPropTypes,
  defaultProps,
  withState,
  withHandlers
} from 'recompose'
import { connect } from 'react-redux'
import {
  isEmpty,
  path,
  pathOr,
  head
} from 'ramda'
import {
  dataLayerProductDetail,
  getAdditionalGenreFromContentItem
} from '../../lib/analytics/datalayer'
import {
  episodeHouseId,
  navigationGa,
  seasonHouseId,
  seriesTitleHouseId
} from '../../lib/analytics/ga'

import {
  getContentParentalRatingDisplay,
  isKids
} from '../../lib/utils'
import { checkIsAuthenticated } from '../../lib/auth'
import { getModalLocation } from '../../lib/modal'
import { getRentalStatus } from '../../lib/content-item'
import TitleDetail from '../../components/title/title-detail'
import TitleDetailKids from '../../components/title/kids/title-detail'
import addToMyListQS from '../../hoc/qs-add-to-my-list'
import withInitializeCastSender from '../../hoc/with-castPlayer-sender-initialize'
import withCastSender from '../../hoc/with-cast-sender'

import MY_LIST_QUERY from '../../../graphql/queries/my-list.gql'
import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'
import MY_RENTALS_QUERY from '../../../graphql/queries/my-rentals.gql'
import CONTENT_ITEM_QUERY from '../../../graphql/queries/content-item.gql'
import PAYMENT_VIEW_QUERY from '../../../graphql/queries/rental-payment-view.gql'
import CONTENT_ITEM_FRAGMENT from '../../../graphql/fragments/content-item.gql'

import { MODALS } from '../../constants'
import {
  COMPONENT_NAME_TITLE_DETAIL,
  COMPONENT_NAME_TITLE_DETAIL_KIDS,
  customDimensions
} from '../../lib/analytics/custom-dimensions'
import {
  currentProfilePropType,
  withCurrentProfile
} from '../../hoc/with-current-profile'
import withMoreInfoExpandState from '../../hoc/with-more-info-expand-state'

const mapStateToProps = (state) => {
  return {
    isAuthenticated: checkIsAuthenticated(state),
    theme: state.theme
  }
}

const rentalOnClick = (history, contentItem, isAuthenticated, location) => {
  return () => {
    const modalUrl = getModalLocation(location,
      MODALS.qsParams.loginRental)
    history.push(modalUrl)
  }
}

const enhance = compose(
  withMoreInfoExpandState,
  withCastSender,
  withInitializeCastSender,
  setPropTypes({
    dataLayerProductDetailFunction: PropTypes.func,
    navigationGaFunction: PropTypes.func,
    currentProfile: currentProfilePropType,
    contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired
  }),
  defaultProps({
    dataLayerProductDetailFunction: dataLayerProductDetail,
    navigationGaFunction: navigationGa
  }),
  withRouter,
  withApollo,
  withCurrentProfile,
  connect(
    mapStateToProps
  ),
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated
  }),
  graphql(MY_LIST_QUERY, {
    name: 'myListQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    options: {
      notifyOnNetworkStatusChange: true
    },
    props: ({ ownProps, myListQuery: { mylist, loading, error } }) => ({
      ...ownProps,
      myList: pathOr([], ['items'], mylist),
      loading,
      error
    })
  }),
  graphql(MY_RENTALS_QUERY, {
    name: 'myRentalsQuery',
    skip: ownProps => !ownProps.isAuthenticated,
    options: ({ contentItem }) => ({ variables: { id: contentItem.id } }),
    props: ({ ownProps, myRentalsQuery: { myRentalHistory, loading, error } }) => ({
      ...ownProps,
      myRentalHistory,
      loading: ownProps.loading || loading,
      error: ownProps.error || error
    })
  }),
  graphql(PAYMENT_VIEW_QUERY, {
    name: 'paymentViewQuery',
    skip: ({ isAuthenticated, contentItem }) => !isAuthenticated || isEmpty(path(['products'], contentItem)) || !pathOr(false, ['isRental'], contentItem),
    options: ({ contentItem }) => {
      const selectedProduct = head(pathOr({}, ['products'], contentItem))
      return {
        variables: {
          contentItemId: contentItem.id,
          productId: selectedProduct.id
        }
      }
    }
  }),
  graphql(CONTENT_ITEM_QUERY, {
    name: 'contentItemQuery',
    options: ({ contentItem }) => ({
      variables: { id: contentItem.id },
      fetchPolicy: 'network-only'
    }),
    props: ({ ownProps, contentItemQuery: { contentItem, loading, error } }) => ({
      ...ownProps,
      contentItem,
      loading: ownProps.loading || loading,
      error: ownProps.error || error
    })
  }),
  branch(
    ({ loading, error }) => (loading || error),
    renderNothing
  ),
  withProps(({
    contentItem,
    myRentalHistory,
    paymentViewQuery,
    playbackInfoQuery,
    accountQuery,
    ...ownProps
  }) => ({
    notes: pathOr([], ['rentalPaymentView', 'notes'], paymentViewQuery),
    rating: getContentParentalRatingDisplay(contentItem),
    reason: pathOr('', ['rating', 'reason'], contentItem),
    advisories: pathOr('', ['rating', 'advisories'], contentItem),
    rentalStatus: getRentalStatus(contentItem, myRentalHistory),
    rentalOnClick: rentalOnClick(
      ownProps.history,
      contentItem,
      ownProps.isAuthenticated,
      ownProps.location,
      ownProps
    ),
    account: accountQuery?.account
  })),
  addToMyListQS,
  lifecycle({
    componentDidMount() {
      const {
        contentItem,
        dataLayerProductDetailFunction,
        navigationGaFunction,
        currentProfile,
        theme,
        castPreviewPageSender
      } = this.props
      castPreviewPageSender(contentItem)
      dataLayerProductDetailFunction(contentItem)
      navigationGaFunction({
        action: 'showPage',
        label: contentItem.title,
        [customDimensions.ShowTitle]: contentItem.title,
        [customDimensions.ProfileId]: currentProfile && currentProfile.id,
        [customDimensions.ProfileName]: currentProfile && currentProfile.name,
        [customDimensions.SeasonNumber]: contentItem.seasonNumber,
        [customDimensions.EpisodeNumber]: contentItem.episodeNumber,
        [customDimensions.AdditionalGenre]: getAdditionalGenreFromContentItem(contentItem),
        [customDimensions.SeriesTitleHouseId]: seriesTitleHouseId(contentItem),
        [customDimensions.SeasonHouseId]: seasonHouseId(contentItem),
        [customDimensions.EpisodeHouseId]: episodeHouseId(contentItem),
        [customDimensions.ComponentName]: isKids(theme)
          ? COMPONENT_NAME_TITLE_DETAIL_KIDS : COMPONENT_NAME_TITLE_DETAIL
      })
    }
  }),
  withState('isExpanded', 'setIsExpanded', false),
  withHandlers({
    onReadMoreClick: ({ isExpanded, setIsExpanded }) => () => {
      setIsExpanded(!isExpanded)
    }
  }),
  branch(
    ({ theme }) => isKids(theme),
    renderComponent(TitleDetailKids),
    renderComponent(TitleDetail)
  )
)

export default enhance(TitleDetail)
